<template>
  <div class="card position-sticky top-1">
    <ul class="nav flex-column bg-white border-radius-lg p-3">
      <li
        v-for="(item, i) in items"
        :key="item.name"
        :class="[
          'nav-item',
          { 'pt-2': i },
        ]"
      >
        <a
          class="nav-link text-dark d-flex"
          data-scroll
          :href="item.href"
        >
          <i class="material-icons text-lg me-2">
            {{ item.icon }}
          </i>

          <span class="text-sm">
            {{ item.name }}
          </span>
        </a>
      </li>
    </ul>

    <slot />
  </div>
</template>

<script>
export default {
  name: "SideNav",

  props: {
    items: {
      type: Array,
      required: true,
      validator: (value) => value
        .every(
          (item) => [
            'href',
            'icon',
            'name',
          ]
            .every(
              (key) => Object.keys(item).includes(key),
            )
        ),
    },
  },
};
</script>
